import React from 'react'
import { InfoPageHeader } from 'Components'
import './iky-tos.css'

export default function TermsOfService() {
  return (
    <>
      <div className="text-darkerGrey text-lg">
        <InfoPageHeader
          pageTitle="Terms of Service"
          subTitle="Last updated: November 13th, 2024"
        />
        <div className="mx-8 lg:mx-auto max-w-text mb-12 iky-tos">
          <p className="c68 title">
            <span className="c56">
              IKnowYou, a service of Cybersecurity Girl LLC.com Terms of Service
            </span>
          </p>
          <p className="c14">
            <span className="c11">Please Read Carefully</span>
            <span className="c30 c65">.</span>
          </p>
          <p className="c47 c64">
            <span className="c4">
              The services that IKnowYou, a service of Cybersecurity Girl LLC
              US, Inc., and its affiliates and subsidiaries (including
              HelloPrivacy LLC d/b/a IKnowYou, a service of Cybersecurity Girl
              LLC, and collectively hereinafter &ldquo;IKnowYou, a service of
              Cybersecurity Girl LLC&rdquo;, &ldquo;we&rdquo;,
              &ldquo;our&rdquo;, and &ldquo;us&rdquo;), provides to you when you
              enroll in our financial wellness product offerings, including any
              content, data and information made available through such
              offerings, and the proprietary technology used to deliver such
              offerings (collectively, the &ldquo;Services&rdquo;), which are
              made available to you as a benefit of your employment with
              IKnowYou, a service of Cybersecurity Girl LLC, are subject to and
              governed by the terms and conditions set forth herein
              (&ldquo;Terms of Service&rdquo;).
            </span>
          </p>
          <p className="c9 c47">
            <span className="c4">
              In this Terms of Service, &ldquo;you&rdquo; or &ldquo;your&rdquo;
              refers to each user of the Services who is at least sixteen (16)
              years of age and has enrolled in our Services by creating an
              account at IKnowYou, a service of Cybersecurity Girl LLC.com. By
              enrolling in our Services, you represent and warrant that you are
              at least sixteen (16) years of age. You understand that by
              enrolling in the Services, you are submitting an electronic
              signature and entering into a legally binding contract with us for
              the provision of such Services.
            </span>
          </p>
          <p className="c84">
            <span className="c4">
              By enrolling in the Services, using the Services, and/or
              exercising any of the rights granted to you in this Terms of
              Service, you accept and agree to be bound and abide by these Terms
              of Service and our Privacy Policy (available here:
              https://iknowyou.com/legal/#privacy), which are presented to you
              when you enroll in the Services and are hereby incorporated herein
              by reference. If you do not agree to this Terms of Service or the
              Privacy Policy, you must not access or use the Services.
            </span>
          </p>
          <p className="c9">
            <span className="c7">ARBITRATION NOTICE: </span>
            <span className="c4">
              EXCEPT FOR CERTAIN TYPES OF DISPUTES DESCRIBED IN THE DISPUTE
              RESOLUTION SECTION BELOW, YOU AGREE THAT DISPUTES BETWEEN YOU AND
              ARRAY WILL BE RESOLVED BY BINDING ARBITRATION AND YOU WAIVE ANY
              RIGHT TO PARTICIPATE IN A CLASS-ACTION LAWSUIT AND/OR CLASS-WIDE
              ARBITRATION.
            </span>
          </p>
          <div className="margin-adjustment">
            <ol className="c5 lst-kix_list_1-0 start" start="1">
              <li className="c36 li-bullet-0">
                <h1 className="inline">
                  <span className="c7">CHANGES TO TERMS OF SERVICE</span>
                </h1>
              </li>
            </ol>
            <p className="c10">
              <span className="c7"></span>
            </p>
            <ol className="c5 lst-kix_list_1-1 start" start="1">
              <li className="c74 li-bullet-1">
                <span className="c8">
                  IKnowYou, a service of Cybersecurity Girl LLC reserves the
                  right to change these Terms of Service at any time, and by
                  continuing to use the Services after we post a change to the
                  online Terms of Service, you will be deemed to have accepted
                  the new Terms of Service. Therefore, you should regularly
                  check these Terms of Service for updates.
                </span>
              </li>
            </ol>
            <ol className="c5 lst-kix_list_1-0" start="2">
              <li className="c52 li-bullet-0">
                <h1 className="inline">
                  <span className="c7">DESCRIPTION OF SERVICES</span>
                </h1>
              </li>
            </ol>
            <p className="c10">
              <span className="c7"></span>
            </p>
            <ol className="c5 lst-kix_list_1-1" start="2">
              <li className="c76 li-bullet-1">
                <span className="c7">General. </span>
                <span className="c4">
                  The Services include identity protection services with dark
                  web monitoring, social security monitoring, change of address
                  monitoring, identity theft insurance and restoration, personal
                  information protection with monitoring and removal of personal
                  information from data broker and people search sites, and
                  subscription management tools.
                </span>
              </li>
              <li className="c17 li-bullet-1">
                <span className="c7">Enrollment. </span>
                <span className="c4">
                  Upon enrolling in the Services and our completion of any
                  required authentication procedures, you will receive access to
                  the Services, where you can access the product offerings and
                  benefits included in the Services.
                </span>
              </li>
              <li className="c70 li-bullet-2">
                <span className="c7">Updates. </span>
                <span className="c4">
                  The Services may be modified or updated at any time without
                  notice and/or liability. Your continued use of the Services,
                  or your failure to terminate your enrollment, after any such
                  modifications have been made to the Services, will constitute
                  your acceptance of those modifications.
                </span>
              </li>
            </ol>
            <ol className="c5 lst-kix_list_1-0" start="3">
              <li className="c0 li-bullet-3">
                <h1 className="inline">
                  <span className="c7">USE AND AVAILABLITY</span>
                </h1>
              </li>
            </ol>
            <p className="c26">
              <span className="c7"></span>
            </p>
            <ol className="c5 lst-kix_list_1-1" start="5">
              <li className="c75 li-bullet-1">
                <span className="c7">Right to Use. </span>
                <span className="c4">
                  This Terms of Service permits you to use the Services only for
                  your personal informational purposes and is subject to this
                  Terms of Service and our Privacy Policy. Commercial use of the
                  Services is not permitted.
                </span>
              </li>
              <li className="c54 li-bullet-1">
                <span className="c7">Use and Availability. </span>
                <span className="c4">
                  While we use reasonable efforts to keep the Services
                  accessible, the Services may be unavailable from time to time.
                  You understand and agree that there may be interruptions to
                  the Services due to circumstances both within our control and
                  outside of our control, for which we will bear no
                  responsibility.
                </span>
              </li>
              <li className="c6 li-bullet-1">
                <span className="c7">Testing and Development.</span>
                <span className="c4">
                  &nbsp; Some of the Services may not have been made
                  commercially available and may not operate properly, be in
                  final form or fully functional, may contain errors, design
                  flaws, or other problems, and that use of the Services may
                  result in unexpected results. &nbsp;
                </span>
              </li>
              <li className="c71 li-bullet-1">
                <span className="c7">Limitations on Use. </span>
                <span className="c4">
                  You are only allowed to enroll yourself, and you agree to
                  provide complete, accurate and current information in
                  connection with the Services and to update this information
                  when it changes. We do not permit any person to sign up for
                  the Services on behalf of another person unless you have the
                  requisite legal authority, such as may be provided by an
                  executed Power of Attorney.
                </span>
              </li>
              <li className="c77 li-bullet-1">
                <span className="c7">Access. </span>
                <span className="c4">
                  You are responsible for obtaining at your own expense all
                  equipment and services needed to access and use the Services,
                  including all devices, internet browsers and internet access.
                  If you access the Services through a mobile or wireless
                  device, you are responsible for all fees that your carrier may
                  charge you for data, text messaging and other wireless access
                  or communications services.
                </span>
              </li>
              <li className="c29 li-bullet-1">
                <span className="c8">
                  Restrictions. Except as may be expressly permitted by
                  applicable law you may not: (i) access the Services and/or any
                  information provided to you in connection with the Services
                  for any purpose which is not authorized under this Terms of
                  Service or for any unlawful purpose; (ii) obtain and/or
                  attempt to obtain any information, materials or data through
                  any means not intentionally made available and/or provided for
                  through the Services; (iii) attempt to modify, make derivative
                  works of, disassemble, reverse compile and/or reverse engineer
                  the Services or any part-thereof; (iv) incorporate any portion
                  of the Services into your own programs or compile any portion
                  of it in combination with your own programs, transfer it for
                  use with another service, or sell rent lease, lend, loan,
                  distribute or sublicense the Services or otherwise assign any
                  rights to the Services or any portion thereof; (v) access all
                  or any part of the Services in order to build a product or
                  service which competes with the Services; (vi) submit and/or
                  transmit any emails, other communications, or materials
                  through, or in connection with the Services that contain a
                  virus, worm, &lsquo;Trojan Horse&rsquo;, worm, logic bomb or
                  other material that is malicious or technologically harmful
                  including a harmful component designed to interrupt, destroy
                  and/or limit the functionality of any computer software,
                  application, hardware and/or telecommunications equipment,
                  and/or that is designed to obtain unauthorized access to any
                  information; (vii) pretend to be someone else, use someone
                  else&rsquo;s identity or misrepresent your affiliation with a
                  person or entity; (viii) copy or imitate part or all of the
                  design, layout, and/or look-and-feel of the Services and/or
                  individual sections of it, in any form or media (except that
                  you may retain a copy of any content or information provided
                  to you through the Services for the purposes authorized
                  herein); (ix) use any automated or programmatic method to
                  extract data or output from the Services, (x) take any actions
                  that in our discretion imposes, or may impose an unreasonable
                  load on the Services, (xi) interfere or attempt to interfere
                  with the proper working of or delivery of the Services, (xii)
                  bypass any measures used to prevent or restrict access to the
                  Services; (xiii) harm, abuse or harass another person; (xiv)
                  send or post any unsolicited or unauthorized advertising,
                  &ldquo;junk mail,&rdquo; &ldquo;spam,&rdquo; &ldquo;chain
                  letters&rdquo; or any form of solicitation unauthorized by
                  IKnowYou, a service of Cybersecurity Girl LLC; (xv) develop,
                  support or use software, devices, scripts, robots, or any
                  other means or processes (including crawlers, browser plugins
                  and add-ons, or any other technology or manual work) to scrape
                  the Services or otherwise copy profiles and other data from
                  the Services; (xvi) deep-link to our Services for any purpose
                  other than to promote your profile without IKnowYou, a service
                  of Cybersecurity Girl LLC&rsquo;s consent; (xvii) remove any
                  copyright, trademark or other proprietary rights notices
                  contained in or on our Service; (xviii) monitor the
                  Services&rsquo; availability, performance or functionality for
                  any competitive purpose; (xix) engage in
                  &ldquo;framing,&rdquo; &ldquo;mirroring,&rdquo; or otherwise
                  simulating the appearance or function of the Services; (xx)
                  overlaying or otherwise modifying the Services or their
                  appearance; or (xxi) interfere with the operation of, or place
                  an unreasonable load on, the Services (e.g., spam, denial of
                  service attack, viruses, gaming algorithms)..
                </span>
              </li>
              <li className="c41 li-bullet-1">
                <span className="c7">Responsibilities. </span>
                <span className="c4">
                  You agree to: (i) use reasonable efforts to prevent
                  unauthorized access to and/or use of the Services and to
                  notify us promptly of such unauthorized access and/or use;
                  (ii) be responsible for the accuracy and quality of data you
                  provide in connection with the Services; and (iii) act in
                  accordance with all applicable laws, rules and regulations.
                </span>
              </li>
              <li className="c22 li-bullet-1">
                <span className="c7">Denial of Service. </span>
                <span className="c4">
                  We retain the right, in our sole and absolute discretion, to
                  deny service, access to, and/or use of the Services to anyone
                  at any time and for any reason without liability including but
                  not limited to violation of the restrictions set forth herein,
                  violation of law, and/or other commercially reasonable
                  security or legal concerns.
                </span>
              </li>
              <li className="c20 li-bullet-4">
                <span className="c8">
                  Eligibility. &nbsp;You agree that you are eligible to enter
                  into these Terms of Service and you are at least 16 years old
                  or older; you will only have one IKnowYou, a service of
                  Cybersecurity Girl LLC account, which must be in your real
                  name; you are not already restricted by us from using the
                  Services; and you will not imply or state that you are
                  affiliated or endorsed by IKnowYou, a service of Cybersecurity
                  Girl LLC without our express consent (e.g., representing
                  yourself as an accredited IKnowYou, a service of Cybersecurity
                  Girl LLC trainer).
                </span>
              </li>
              <li className="c63 li-bullet-4">
                <span className="c7">Security</span>
                <span className="c4">
                  . &nbsp;You agree to: (i) try to choose a strong and secure
                  password; (ii) keep your password secure and confidential; and
                  (iii) not transfer any part of your account (e.g.,
                  connections). &nbsp;You are responsible for anything that
                  happens through your account unless you close it or report
                  misuse.
                </span>
                <span className="c30 c34">&nbsp;</span>
                <span className="c4">
                  As between you and others (including your employer, school or
                  university), your account belongs to you. However, if the
                  Services were purchased by another party for you to use (e.g.
                  your employer, school or university), the party paying for
                  such Service has the right to control access to and get
                  reports on your use of such paid Service; however, they do not
                  have rights to your personal account. <br />
                </span>
              </li>
            </ol>
            <ol className="c5 lst-kix_list_1-0" start="4">
              <li className="c0 li-bullet-3">
                <h1 className="inline">
                  <span className="c7">PRODUCT SPECIFIC INFORMATION</span>
                </h1>
              </li>
            </ol>
            <ol className="c5 lst-kix_list_1-1 start" start="1">
              <li className="c28 li-bullet-1">
                <span className="c8">
                  IKnowYou, a service of Cybersecurity Girl LLC. &nbsp;The
                  IKnowYou, a service of Cybersecurity Girl LLC Service will
                  regularly use your Information to scan online data source
                  websites (the &quot;Data Sites&quot;) for personal information
                  about you. Sometimes these websites are called people search
                  sites. If online personal information about you is identified
                  at these Data Sites, we will submit opt-out requests on your
                  behalf to remove it (&quot;Opt-out&quot;). You can go to the
                  IKnowYou, a service of Cybersecurity Girl LLC site at any time
                  to see if online personal information about you was found at
                  these websites and whether the opt-out was successful. We may
                  change the Data Sites that are being scanned at any time
                  without notice to you.
                </span>
              </li>
            </ol>
            <ol className="c5 lst-kix_list_1-0" start="5">
              <li className="c0 li-bullet-3">
                <h1 className="inline">
                  <span className="c7">CONSUMER AUTHORIZATION</span>
                </h1>
              </li>
            </ol>
            <ol className="c5 lst-kix_list_1-1 start" start="1">
              <li className="c53 li-bullet-2">
                <span className="c8">
                  Authorization to Act on Your Behalf. By enrolling in the
                  Services you agree that you expressly authorize IKnowYou, a
                  service of Cybersecurity Girl LLC and its agents, servicers,
                  and employees, to act as your personal representative in order
                  to submit opt-out requests to data broker sites and to (i)
                  obtain information on your behalf; (ii) submit your personal
                  information to the data broker websites; (iii) communicate
                  with the data broker websites or other third parties on your
                  behalf; (iv) agree to applicable terms and conditions imposed
                  by data broker websites; (v) complete and sign or execute
                  documents on your behalf; (vi) and take other actions that we
                  believe is reasonably necessary to complete an opt-out request
                  from a data broker website or to provide the Services.
                </span>
              </li>
            </ol>
            <ol className="c5 lst-kix_list_1-0" start="6">
              <li className="c27 li-bullet-5">
                <h1 className="inline">
                  <span className="c7">PAYMENT</span>
                </h1>
              </li>
            </ol>
            <ol className="c5 lst-kix_list_1-1 start" start="1">
              <li className="c78 li-bullet-1">
                <h1 className="inline">
                  <span className="c4">
                    If you purchase any paid Services (&ldquo;Premium
                    Services&rdquo;), you agree to pay us the applicable fees
                    and taxes in addition to terms specific to the Premium
                    Services. &nbsp;Failure to pay these fees will result in the
                    termination of your Premium Services. &nbsp;Also, you agree
                    that
                  </span>
                </h1>
              </li>
            </ol>
            <ol className="c5 lst-kix_list_1-2 start" start="1">
              <li className="c3 li-bullet-6">
                <h1 className="inline">
                  <span className="c4">
                    Your purchase may be subject to foreign exchange fees or
                    differences in prices based on location (e.g., exchange
                    rates).
                  </span>
                </h1>
              </li>
              <li className="c3 li-bullet-6">
                <h1 className="inline">
                  <span className="c4">
                    We may store and continue billing your payment method (e.g.,
                    credit card) even after it has expired, to avoid
                    interruptions in your Services and to use to pay other
                    Services you may buy from us.
                  </span>
                </h1>
              </li>
              <li className="c3 li-bullet-7">
                <h1 className="inline">
                  <span className="c4">
                    If you purchase a subscription, your payment method
                    automatically will be charged at the start of each
                    subscription period for the fees and taxes applicable to
                    that period. &nbsp;To avoid future charges, cancel before
                    the renewal date.
                  </span>
                </h1>
              </li>
              <li className="c3 li-bullet-8">
                <h1 className="inline">
                  <span>
                    All of your purchases of Services are subject to IKnowYou, a
                    service of Cybersecurity Girl LLC&rsquo;s .
                  </span>
                </h1>
              </li>
              <li className="c2 li-bullet-9">
                <h1 className="inline">
                  <span className="c4">
                    We may calculate taxes payable by you based on the billing
                    information that you provide us at the time of purchase.
                  </span>
                </h1>
              </li>
            </ol>
            <ol className="c5 lst-kix_list_1-0" start="7">
              <li className="c85 li-bullet-10">
                <h1 className="inline">
                  <span className="c7">
                    THIRD PARTY SERVICE PROVIDERS
                    <br />
                  </span>
                </h1>
              </li>
            </ol>
            <ol className="c5 lst-kix_list_1-1 start" start="1">
              <li className="c23 li-bullet-4">
                <span className="c8">
                  The Services are accessed by you and provided to you in
                  connection with third-party services and information that
                  IKnowYou, a service of Cybersecurity Girl LLC does not
                  control. The Services include access to an integrated display
                  of certain consumer data collected and compiled from various
                  third-party data provider sources, and include third-party
                  product offerings, such as identity insurance and restoration
                  services. We may also use other third-party service providers
                  to provide the Services or to gather data and authenticate
                  information regarding you, your device, and your accounts. In
                  some instances, we may require you to accept third-party
                  software tools and products through the Services. In addition
                  to these Terms of Service, use of such third-party services
                  may be governed by additional terms and conditions provided by
                  the third-party service providers. Use or continued use of
                  such third-partyservices after review of such additional terms
                  shall constitute acceptance of such terms. Any information,
                  data, services or products provided by third parties
                  (integrated into the Services or otherwise), shall be provided
                  &ldquo;AS IS&rdquo; without warranty of any kind by IKnowYou,
                  a service of Cybersecurity Girl LLC; and IKnowYou, a service
                  of Cybersecurity Girl LLC assumes no responsibility for, and
                  specifically disclaims any liability or obligation with
                  respect to third-party service providers.
                </span>
              </li>
            </ol>
            <ol className="c5 lst-kix_list_1-0" start="8">
              <li className="c36 li-bullet-5">
                <h1 className="inline">
                  <span className="c7">CANCELLATION</span>
                </h1>
              </li>
            </ol>
            <ol className="c5 lst-kix_list_1-1 start" start="1">
              <li className="c37 li-bullet-11">
                <span className="c7">Enrollment Cancellation. </span>
                <span className="c4">
                  YOU MAY CANCEL YOUR ENROLLMENT AT ANY TIME BY EMAILING YOUR
                  REQUEST TO{' '}
                </span>
                <span className="c30 c31">
                  <a className="c15" href="mailto:SUPPORT@BRANDYOURSELF.COM">
                    SUPPORT@BRANDYOURSELF.COM
                  </a>
                </span>
                <span className="c4">&nbsp;OR BY CALLING 646-863-8226.</span>
              </li>
            </ol>
            <ol className="c5 lst-kix_list_1-0" start="9">
              <li className="c27 li-bullet-3">
                <h1 className="inline">
                  <span className="c7">
                    ERRORS, INACCURACIES, OMISSIONS AND PERFORMANCE
                  </span>
                </h1>
              </li>
            </ol>
            <p className="c10">
              <span className="c7"></span>
            </p>
            <ol className="c5 lst-kix_list_1-1" start="2">
              <li className="c58 li-bullet-1">
                <span className="c4">
                  Occasionally there may be information on the Services that
                  contain typographical errors, inaccuracies, and/or omissions.
                  We reserve the right to: (i) correct any errors, inaccuracies,
                  and/or omissions; and/or (ii) make changes to content,
                  descriptions, Services and/or other information without
                  obligation to issue any notice of such changes, except as
                  prohibited by law.
                </span>
              </li>
            </ol>
            <ol className="c5 lst-kix_list_1-0" start="10">
              <li className="c52 li-bullet-12">
                <h1 className="inline">
                  <span className="c7">PROPRIETARY RIGHTS AND LICENSES</span>
                </h1>
              </li>
            </ol>
            <p className="c10">
              <span className="c7"></span>
            </p>
            <ol className="c5 lst-kix_list_1-1" start="3">
              <li className="c12 li-bullet-1">
                <span className="c8">
                  Ownership. The Services, any content and/or products on the
                  Services and the infrastructure used to provide the Services
                  are proprietary to IKnowYou, a service of Cybersecurity Girl
                  LLC, our affiliates, and/or our third-party service providers.
                  By enrolling in the Services and accepting this Terms of
                  Service, IKnowYou, a service of Cybersecurity Girl LLC grants
                  you a limited, personal, nontransferable, nonexclusive,
                  non-sublicensable, revocable right to access and/or use the
                  Services and information provided therein pursuant to this
                  Terms of Service.
                </span>
              </li>
              <li className="c44 li-bullet-1">
                <span className="c8">
                  Reservation of Rights. The materials, headers, illustrations,
                  graphics and/or any other content on the Services, as well as
                  the organization and layout of the Services, are copyrighted
                  and are protected by United States and international copyright
                  laws and treaty provisions. Subject to the limited rights
                  expressly granted hereunder, IKnowYou, a service of
                  Cybersecurity Girl LLC and/or its third-party service
                  providers reserve all right, title and interest in and to the
                  Services and content, including all related worldwide
                  intellectual property rights. No rights are granted to you
                  hereunder other than as expressly set forth herein.
                </span>
              </li>
              <li className="c44 li-bullet-1">
                <span className="c8">
                  License grant from you to IKnowYou, a service of Cybersecurity
                  Girl LLC. &nbsp;As between you and IKnowYou, a service of
                  Cybersecurity Girl LLC, you own the content and information
                  that you submit or post to the Services and you are only
                  granting IKnowYou, a service of Cybersecurity Girl LLC and our
                  affiliates the following non-exclusive license: a worldwide,
                  transferable and sublicensable right to use, copy, modify,
                  distribute, publish, and process, information and content that
                  you provide through our Services, without any further consent,
                  notice and/or compensation to you or others. &nbsp;You can end
                  this license for specific content by deleting such content
                  from the Services, or generally by closing your account,
                  except for the reasonable time it takes to remove from backup
                  and other systems. We will not include your content in
                  advertisements for the products and services of third parties
                  to others without your separate consent (including sponsored
                  content). However, we have the right, without payment to you
                  or others, to serve ads near your content and information, and
                  your social actions on sponsored content and company pages may
                  be visible, as noted in the Privacy Policy; While we may edit
                  and make formatting changes to your content (such as
                  translating it, modifying the size, layout or file type or
                  removing metadata), we will not modify the meaning of your
                  expression. &nbsp;Because you own your content, you may choose
                  to make it available to others. &nbsp;You agree that we may
                  access, store and use any information that you provide in
                  accordance with the terms of the Privacy Policy and your
                  choices (including settings). &nbsp;By submitting suggestions
                  or other feedback regarding our Services to IKnowYou, a
                  service of Cybersecurity Girl LLC, you agree that IKnowYou, a
                  service of Cybersecurity Girl LLC can use and share (but does
                  not have to) such feedback for any purpose without
                  compensation to you. &nbsp;You agree to only provide content
                  or information that does not violate the law nor
                  anyone&rsquo;s rights (including intellectual property
                  rights). You also agree that your profile information will be
                  truthful. IKnowYou, a service of Cybersecurity Girl LLC may be
                  required by law to remove certain information or content in
                  certain countries.
                </span>
              </li>
              <li className="c47 c81 li-bullet-13">
                <span className="c8">
                  Feedback. If you choose to submit any questions, comments,
                  ideas, suggestions, inventions, or other information
                  (&ldquo;Feedback&rdquo;) to IKnowYou, a service of
                  Cybersecurity Girl LLC, such Feedback is non-confidential and
                  will become the sole property of IKnowYou, a service of
                  Cybersecurity Girl LLC. IKnowYou, a service of Cybersecurity
                  Girl LLC will be able to use any Feedback for any purpose
                  including the developing, creating and/or marketing of
                  products or services and you acknowledge IKnowYou, a service
                  of Cybersecurity Girl LLC will give no credit or compensation
                  to you and you have no rights to the same. IKnowYou, a service
                  of Cybersecurity Girl LLC has no obligations to review any
                  Feedback and may use and redistribute Feedback for any purpose
                  without restriction in its sole and absolute discretion.
                </span>
              </li>
            </ol>
            <ol className="c5 lst-kix_list_1-0" start="11">
              <li className="c50 li-bullet-14">
                <h1 className="inline">
                  <span className="c7">TERMINATION</span>
                </h1>
              </li>
            </ol>
            <ol className="c5 lst-kix_list_1-1 start" start="1">
              <li className="c33 li-bullet-4">
                <h1 className="inline">
                  <span className="c30">
                    We may suspend or terminate your enrollment or your use of
                    the Services at any time, for any reason or for no reason,
                    including without limitation in the event that (i) you
                    breach this Terms of Service; (ii) we are unable to verify
                    or authenticate any information you provide to us; or (iii)
                    we believe that your actions may cause financial loss or
                    legal liability for you, our third party service providers,
                    or us. &nbsp;If you become dissatisfied with the Services,
                    your only recourse is to immediately discontinue use of the
                    Services and cancel your enrollment. &nbsp;Upon termination,
                    all rights granted to you will automatically terminate.
                  </span>
                </h1>
              </li>
            </ol>
            <ol className="c5 lst-kix_list_1-0" start="12">
              <li className="c82 li-bullet-15">
                <span className="c7">USER VERIFICATION</span>
              </li>
            </ol>
            <p className="c25">
              <span className="c7"></span>
            </p>
            <ol className="c5 lst-kix_list_1-1" start="2">
              <li className="c46 li-bullet-1">
                <span className="c8">
                  IKnowYou, a service of Cybersecurity Girl LLC may, in its sole
                  discretion, verify a user&rsquo;s identity prior to allowing
                  such user to access and/or use the Services. IKnowYou, a
                  service of Cybersecurity Girl LLC may, without liability,
                  refuse to process and/or may cancel access to the Services, as
                  reasonably deemed necessary, to comply with applicable law
                  and/or to respond to a case of misrepresentation, fraud and/or
                  known and/or potential violations of the law and/or this Terms
                  of Service.
                </span>
              </li>
            </ol>
            <ol className="c5 lst-kix_list_1-0" start="13">
              <li className="c13 li-bullet-14">
                <h1 className="inline">
                  <span className="c7">GEOGRAPHIC RESTRICTIONS</span>
                </h1>
              </li>
            </ol>
            <p className="c10">
              <span className="c7"></span>
            </p>
            <ol className="c5 lst-kix_list_1-1" start="3">
              <li className="c72 li-bullet-1">
                <span className="c8">
                  IKnowYou, a service of Cybersecurity Girl LLC is located in
                  the United States. Access to the Services may not be legal by
                  certain persons or in certain countries. If you access the
                  Services from outside the United States, you do so on your own
                  initiative, at your own risk, and are responsible for
                  compliance with local laws.
                </span>
              </li>
            </ol>
            <ol className="c5 lst-kix_list_1-0" start="14">
              <li className="c13 li-bullet-16">
                <h1 className="inline">
                  <span className="c7">INDEMNIFICATION AND WAIVER</span>
                </h1>
              </li>
            </ol>
            <p className="c10">
              <span className="c7"></span>
            </p>
            <ol className="c5 lst-kix_list_1-1" start="4">
              <li className="c39 li-bullet-1">
                <span className="c8">
                  You shall indemnify, defend and hold harmless IKnowYou, a
                  service of Cybersecurity Girl LLC, its officers, agents,
                  employees, contractors, subcontractors, suppliers, third-party
                  service providers and representatives from and against any and
                  all claims, demands, proceedings, losses, costs, damages,
                  awards, fees, expenses, and/or liabilities of any nature
                  (&ldquo;Claim&rdquo;) arising out of and/or in the relation to
                  (i) your violation of any law and/or the rights of a third
                  party, (ii) any breach of this Terms of Service or the Privacy
                  Policy by you and/or your representatives, (iii) your use of
                  the Services, and/or (iv) fraud you commit and/or your
                  intentional misconduct and/or negligence. You shall give
                  prompt notice to IKnowYou, a service of Cybersecurity Girl LLC
                  upon your receipt of notice of any Claim against you which
                  might give rise to a claim against IKnowYou, a service of
                  Cybersecurity Girl LLC.
                </span>
              </li>
            </ol>
            <ol className="c5 lst-kix_list_1-0" start="15">
              <li className="c50 li-bullet-17">
                <h1 className="inline">
                  <span className="c7">DISCLAIMER OF WARRANTIES</span>
                </h1>
              </li>
            </ol>
            <p className="c10">
              <span className="c7"></span>
            </p>
            <ol className="c5 lst-kix_list_1-1" start="5">
              <li className="c21 li-bullet-1">
                <span className="c4">
                  YOU EXPRESSLY UNDERSTAND AND AGREE THAT, TO THE MAXIMUM EXTENT
                  PERMITTED BY APPLICABLE LAW: THE SERVICES ARE PROVIDED ON AN
                  &ldquo;AS IS&rdquo; AND &ldquo;AS AVAILABLE&rdquo; BASIS, AND
                  THE COMPANY MAKES NO REPRESENTATION OR WARRANTY, EXPRESS OR
                  IMPLIED, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF TITLE,
                  NON-INFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A PARTICULAR
                  PURPOSE. NO WARRANTIES WILL BE CREATED BY COURSE OF DEALING,
                  COURSE OF PERFORMANCE, OR TRADE USAGE, INCLUDING, WITHOUT
                  LIMITATION, THAT THE SERVICES WILL OPERATE ERROR FREE, WITHOUT
                  INTERRUPTION, WILL ACHIEVE ANY INTENDED RESULT, OR THAT THE
                  SERVICES, INCLUDING ANY CONTENT, DATA OR INFORMATION, WILL BE
                  COMPLETE, ACCURATE OR VALID AND ARRAY EXPRESSLY DISCLAIMS ALL
                  SUCH REPRESENTATIONS AND WARRANTIES. ARRAY EXERCISES NO
                  CONTROL OVER AND EXPRESSLY DISCLAIMS ANY LIABILITY ARISING OUT
                  OF OR BASED UPON YOUR USE OF THE SERVICES. NO ADVICE AND/OR
                  INFORMATION, WHETHER ORAL AND/OR WRITTEN, OBTAINED BY YOU FROM
                  ARRAY OR THROUGH YOUR ACCESS TO AND/OR USE OF THE SERVICES
                  SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THIS TERMS
                  OF SERVICE. ARRAY MAKES NO PROMISES WITH RESPECT TO, AND
                  EXPRESSLY DISCLAIMS ALL LIABILITY FOR ANY THIRD-PARTY WEBSITE
                  OR APPLICATION, THIRD-PARTY SERVICES LISTED ON OR ACCESSIBLE
                  TO YOU THROUGH THE SERVICES, INCLUDING ANY THIRD-PARTY
                  SERVICES INTEGRATED INTO THE SERVICES.
                </span>
              </li>
            </ol>
            <ol className="c5 lst-kix_list_1-0" start="16">
              <li className="c69 li-bullet-18">
                <h1 className="inline">
                  <span className="c7">LIMITATION OF LIABILITY</span>
                </h1>
              </li>
            </ol>
            <ol className="c5 lst-kix_list_1-1 start" start="1">
              <li className="c83 li-bullet-4">
                <h1 className="inline">
                  <span className="c4">
                    YOUR USE OF THE SERVICES AND CONTENT, DATA AND INFORMATON IS
                    AT YOUR OWN RISK. IN NO EVENT SHALL ARRAY, ITS OFFICERS,
                    DIRECTORS, EMPLOYEES, PARTNERS, LICENSORS, SUPPLIERS AND/OR
                    THIRD PARTY SERVICE PROVIDERS BE LIABLE TO YOU AND/OR ANYONE
                    ELSE FOR ANY SPECIAL, INCIDENTAL, INDIRECT, CONSEQUENTIAL
                    AND/OR PUNITIVE DAMAGES WHATSOEVER, INCLUDING THOSE
                    RESULTING FROM LOSS OF USE, DATA, WHETHER OR NOT
                    FORESEEABLE, REGARDLESS OF THE FORM OF ACTION, WHETHER IN
                    CONTRACT, TORT (INCLUDING NEGLIGENCE), WARRANTY, STRICT
                    LIABILITY, AND/OR ANY OTHER LEGAL OR EQUITABLE THEORY, EVEN
                    IF ARRAY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES
                    IN ADVANCE. THE FOREGOING SHALL APPLY DESPITE ANY
                    NEGLIGENCE, MISCONDUCT, ERRORS AND/OR OMISSIONS BY ARRAY,
                    ITS EMPLOYEES, REPRESENTATIVES AND/OR AGENTS. BECAUSE SOME
                    STATES DO NOT ALLOW THE EXCLUSION AND/OR LIMITATION OF
                    LIABILITY FOR CONSEQUENTIAL AND/OR INCIDENTAL DAMAGES, IN
                    SUCH STATES, ARRAY&rsquo;S LIABILITY IS LIMITED TO THE
                    MAXIMUM EXTENT PERMITTED BY LAW. ARRAY&rsquo;S MAXIMUM
                    AGGREGATE LIABILITY TO YOU FOR DIRECT DAMAGES THAT YOU
                    SUFFER IN CONNECTION WITH THE SERVICES IS LIMITED TO THE
                    GREATER OF ONE HUNDRED DOLLARS ($100) OR THE AMOUNT YOU HAVE
                    PAID FOR THE SERVICES IN THE PRECEDING SIX (6) MONTHS OF
                    YOUR CLAIM.
                  </span>
                </h1>
              </li>
            </ol>
            <ol className="c5 lst-kix_list_1-0" start="17">
              <li className="c50 li-bullet-17">
                <h1 className="inline">
                  <span className="c7">NO PROFESSIONAL ADVICE</span>
                </h1>
              </li>
            </ol>
            <p className="c25">
              <span className="c7"></span>
            </p>
            <ol className="c5 lst-kix_list_1-1" start="2">
              <li className="c51 li-bullet-1">
                <span className="c4">
                  We are not a credit repair organization, credit counselor, or
                  financial or legal advisor as defined under federal or state
                  law. The Services are not intended to, and do not, constitute
                  legal, professional, or financial advice, and are not a
                  substitute for such advice and may not be used for such
                  purposes. Always seek the advice of your attorney, tax
                  advisor, financial planner, or other professional advisor with
                  any questions you may have regarding such matters. Our agents,
                  representatives, and employees are not authorized to provide
                  any such advice or make any claims of guaranteed savings, debt
                  settlement benefits, or representations of expected results.
                  No written or verbal statement from our representatives shall
                  supplement or amend this Terms of Service. We do not guarantee
                  that any savings, settlement, or particular outcome will be
                  achieved through the Services.
                </span>
              </li>
            </ol>
            <ol className="c5 lst-kix_list_1-0" start="18">
              <li className="c18 li-bullet-19">
                <h1 className="inline">
                  <span className="c7">NO GUARANTEE</span>
                </h1>
              </li>
            </ol>
            <ol className="c5 lst-kix_list_1-1 start" start="1">
              <li className="c43 li-bullet-4">
                <span className="c4">
                  We do not guarantee that the information we present as part of
                  the Services, including credit report or credit score
                  information, is the same information that may be used or
                  reviewed by a third party to assess your creditworthiness,
                  eligibility for any particular product or service, or for
                  employment. We do not represent or guarantee the accuracy or
                  reliability of any advice, opinion, statement, or other
                  information displayed or distributed through the Services. You
                  acknowledge that any reliance upon any such information shall
                  be at your sole risk.
                </span>
              </li>
              <li className="c32 li-bullet-1">
                <span className="c4">
                  We do not guarantee that in connection with your use of the
                  Privacy Info Protection product offering that we will find
                  your online personal information within any third-party data,
                  or that all data broker websites will be scanned. We do not
                  guarantee that the Services will be effective, or that an
                  opt-out request will be honored or responded to by the
                  relevant data-broker websites. We do not guarantee that an
                  opt-out request will prevent all or any future uses or
                  disclosures of your online personal information by a data
                  broker website, or that we will identify all instances of your
                  online personal information that is eligible for opt-out, or
                  that the data broker websites will not retain copies of your
                  online personal information. Some of the data broker websites
                  may have multiple databases and may acquire and aggregate
                  personal information about individuals on an ongoing basis.
                  The opt-out request we submit on your behalf may not be
                  effective for all sources of online personal information
                  maintained by the data broker websites, or for online personal
                  information acquired by the data broker websites after the
                  opt-out request. In addition, we may not locate all entries or
                  the correct entries of your online personal information that
                  are eligible for opt-out. Not all data broker websites offer
                  the same type of opt-out options. Further, in some instances,
                  even if we are able to effectively submit an opt-out request
                  on your behalf to a data broker website, that data broker
                  website may still maintain your online personal information in
                  its records and may fail to honor or properly respond to an
                  opt-out request. You understand and agree that Company is not
                  responsible for the actions or inactions of any of these data
                  broker sites, and that Company makes no representations,
                  warranties or guarantees related to such data broker sites.
                  You expressly agree that Company will not be liable to you
                  under any circumstances for any actions or inactions of these
                  data broker sites, or for Company&rsquo;s failure for any
                  reason to exercise effective opt-outs on your behalf.
                </span>
              </li>
            </ol>
            <ol className="c5 lst-kix_list_1-0" start="19">
              <li className="c16 li-bullet-20">
                <h1 className="inline">
                  <span className="c7">LINKED SITES</span>
                </h1>
              </li>
            </ol>
            <p className="c26">
              <span className="c7"></span>
            </p>
            <ol className="c5 lst-kix_list_1-1" start="3">
              <li className="c24 li-bullet-1">
                <span className="c8">
                  The Services may provide links to various other independent
                  third-party websites (&ldquo;Linked Sites&rdquo;) that may be
                  of interest to you and are for your convenience only.
                  IKnowYou, a service of Cybersecurity Girl LLC does not control
                  and/or endorse such Linked Sites and is not responsible for
                  their content nor is it responsible for the accuracy and/or
                  reliability of any information, data, opinions, advice or
                  statements contained within such Linked Sites. You will need
                  to make your own independent judgment regarding your
                  interaction with these Linked Sites at your own risk. We
                  encourage you to be aware when you leave the Services and to
                  read the terms and conditions and privacy policy of each
                  Linked Site that you visit. IKnowYou, a service of
                  Cybersecurity Girl LLC reserves the right to terminate any
                  link at any time and disclaims all warranties, express and
                  implied, as to the accuracy, validity, and legality and/or
                  otherwise of any materials or information contained on such
                  Linked Sites.
                </span>
              </li>
            </ol>
            <ol className="c5 lst-kix_list_1-0" start="20">
              <li className="c48 li-bullet-15">
                <h1 className="inline">
                  <span className="c7">COMMUNICATIONS</span>
                </h1>
              </li>
            </ol>
            <p className="c26">
              <span className="c7"></span>
            </p>
            <ol className="c5 lst-kix_list_1-1" start="4">
              <li className="c42 li-bullet-1">
                <span className="c4">
                  By using the Services, you agree to receive certain
                  communications in connection with such access and use. When
                  you access and/or use the Services, you are communicating with
                  us electronically, and you consent to receive communications
                  from us electronically including but not limited to notices
                  related to the Services provided to you via email and through
                  the Services. You agree that all agreements, notices,
                  disclosures and/or other communications that we provide to you
                  electronically satisfy any legal requirement that such
                  communications be in writing. Your consent to receive records
                  and notices electronically will remain in effect until you
                  withdraw it. You may withdraw your consent to receive further
                  records and notices electronically at any time by contacting
                  us. If you withdraw your consent to receive such records and
                  notices electronically, we will revoke your access to the
                  Services. Any withdrawal of your consent to receive records
                  and notices electronically will be effective only after we
                  have a reasonable period of time to process your request for
                  withdrawal.
                </span>
              </li>
            </ol>
            <ol className="c5 lst-kix_list_1-0" start="21">
              <li className="c86 li-bullet-21">
                <h1 className="inline">
                  <span className="c7">DISPUTE RESOLUTION</span>
                </h1>
              </li>
            </ol>
            <ol className="c5 lst-kix_list_1-1 start" start="1">
              <li className="c38 li-bullet-4">
                <h1 className="inline">
                  <span>
                    This Agreement shall be governed and construed in accordance
                    with the laws of the State of New York, without resort to
                    the conflict of law principles thereof. Except as it relates
                    to IKnowYou, a service of Cybersecurity Girl LLC&rsquo;s
                    efforts to collect unpaid Fees, any controversy, claim or
                    dispute between the parties arising out of or relating to
                    this Terms of Service or the breach, termination,
                    enforcement, interpretation, conscionability or validity
                    thereof, including any determination of the scope or
                    applicability of this agreement to arbitrate, such
                    controversy, claim or dispute shall be resolved by
                    mandatory, binding arbitration, rather than in court. The
                    parties agree that the arbitration shall be administered by
                    the American Arbitration Association (AAA), or other
                    nationally recognized arbitrator as agreed upon by the
                    parties and the parties shall be bound by any and all rules
                    of AAA or other mutually accepted rules and any
                    award/decision rendered. Notwithstanding anything to the
                    contrary in the AAA rules, the arbitrator will be empowered
                    to order interim or permanent injunctive relief, which may
                    be enforced in any court of competent jurisdiction. Any
                    decision or award as a result of any such arbitration
                    proceeding shall be in writing and shall provide an
                    explanation for all decisions. Any such arbitration shall
                    include a written record of the arbitration hearing and
                    shall be conducted by an arbitrator as agreed upon by the
                    parties, or if no agreement can be made, then an arbitrator
                    shall be selected by AAA experienced in complex business
                    transactions. The award rendered by the arbitrator shall be
                    final and shall not be subject to vacation or modification.
                    Judgment on the award made by the arbitrator may be entered
                    in any court having jurisdiction over the parties. If either
                    party fails to comply with the arbitrator&#39;s award, the
                    injured party may petition the circuit court for
                    enforcement.
                  </span>
                </h1>
              </li>
              <li className="c38 li-bullet-4">
                <h1 className="inline">
                  <span className="c4">
                    The parties agree that either party may bring claims against
                    the other only in his/her or its individual capacity and not
                    as a plaintiff or class member in any purported class or
                    representative proceeding. Further, the parties agree that
                    the arbitrator may not consolidate proceedings of more than
                    one person&#39;s claims and may not otherwise preside over
                    any form of representative or class proceeding.
                  </span>
                </h1>
              </li>
              <li className="c38 li-bullet-4">
                <h1 className="inline">
                  <span className="c4">
                    The parties shall share the cost (not any attorneys&#39;
                    fees) of arbitration equally. In the event a party fails to
                    proceed with arbitration as required herein, unsuccessfully
                    challenges the arbitrator&#39;s award, or fails to comply
                    with the arbitrator&#39;s award, the other party is entitled
                    to costs of suit including a reasonable attorney&#39;s fee
                    for having to compel arbitration or defend or enforce the
                    award. Binding Arbitration means that both parties give up
                    the right to a trial by a jury or to use the court system
                    except to enforce this section. It also means that both
                    parties give up the right to appeal from the
                    arbitrator&#39;s ruling except for a narrow range of issues
                    that can or may be appealed. It also means that discovery
                    may be severely limited by the arbitrator. This section and
                    the arbitration requirement shall survive any termination.
                  </span>
                </h1>
              </li>
            </ol>
            <ol className="c5 lst-kix_list_1-0" start="22">
              <li className="c35 li-bullet-17">
                <h1 className="inline">
                  <span className="c7">CALIFORNIA USERS</span>
                </h1>
              </li>
            </ol>
            <p className="c10">
              <span className="c7"></span>
            </p>
            <ol className="c5 lst-kix_list_1-1" start="4">
              <li className="c59 li-bullet-1">
                <span className="c8">
                  Under California Civil Codes, California residents are
                  entitled to additional specific consumer rights information.
                  To file a complaint regarding the Services or to receive
                  further information regarding use of the Services, including
                  to cancel your Services, please contact us at IKnowYou, a
                  service of Cybersecurity Girl LLC US, Inc., 2200 N. Federal
                  Hwy., Suite 217, Boca Raton, FL 33431, or call us toll free at
                  (833) 742-0200, or email us at &nbsp;You may also contact the
                  Complaint Assistance Unit of the Division of Consumer Services
                  of the Department of Consumer Affairs in writing at 400 R
                  Street, Suite 1080, Sacramento, California 95814, or by
                  telephone at 916.445.1254 or 800.952.5210.
                </span>
              </li>
            </ol>
            <ol className="c5 lst-kix_list_1-0" start="23">
              <li className="c50 li-bullet-20">
                <h1 className="inline">
                  <span className="c7">MISCELLANEOUS</span>
                </h1>
              </li>
            </ol>
            <p className="c10">
              <span className="c7"></span>
            </p>
            <ol className="c5 lst-kix_list_1-1" start="5">
              <li className="c61 li-bullet-1">
                <span className="c8">
                  You may not assign your rights and/or obligations under this
                  Terms of Service to any other party without our prior written
                  consent. We may assign our rights and/or obligations under
                  this Terms of Service to any other party at our discretion. If
                  any provision and/or term of this Terms of Service shall
                  become or be declared illegal, invalid or unenforceable for
                  any reason whatsoever, such term and/or provision shall be
                  divisible from the other terms and conditions and shall be
                  deemed to be deleted from them. If an applicable law is in
                  conflict with any part of this Terms of Service, this Terms of
                  Service will be deemed modified to conform to the law and the
                  other provisions will not be affected by any such
                  modification. No waiver by either you and/or IKnowYou, a
                  service of Cybersecurity Girl LLC of any breach and/or default
                  and/or failure to exercise any right allowed under this Terms
                  of Service is a waiver of any preceding and/or subsequent
                  breach and/or default and/or a waiver and/or forfeiture of any
                  similar and/or future rights under this Terms of Service. All
                  provisions in these Terms of Service regarding representations
                  and warranties, indemnification, disclaimers, and limitation
                  of liability will survive the termination of this Terms of
                  Service.
                </span>
              </li>
            </ol>
            <ol className="c5 lst-kix_list_1-0" start="24">
              <li className="c80 li-bullet-22">
                <h1 className="inline">
                  <span className="c7">CONTACT US</span>
                </h1>
              </li>
            </ol>
            <p className="c10">
              <span className="c7"></span>
            </p>
            <ol className="c5 lst-kix_list_1-1" start="6">
              <li className="c45 li-bullet-1">
                <span className="c4">
                  If you would like to request additional information regarding
                  this Terms of Service or if you have feedback about our
                  Services, or need support regarding the Services please
                  contact us at{' '}
                </span>
                <span className="c30 c73">
                  <a
                    className="c15"
                    href="mailto:iknowyou@cybersecuritygirl.com"
                  >
                    iknowyou@cybersecuritygirl.com
                  </a>
                </span>
                <span className="c4">.</span>
              </li>
            </ol>
            <ol className="c5 lst-kix_list_1-0" start="25">
              <li className="c57 li-bullet-23">
                <h1 className="inline">
                  <span className="c7">IMPORTANT CONSUMER NOTICES</span>
                </h1>
              </li>
            </ol>
            <ol className="c5 lst-kix_list_1-1 start" start="1">
              <li className="c67 li-bullet-4">
                <span className="c7">Notice Required by Law. </span>
                <span className="c4">
                  The federal Fair Credit Reporting Act (FCRA) promotes the
                  accuracy, fairness, and privacy of information in the files of
                  consumer reporting agencies. There are many types of consumer
                  reporting agencies, including credit bureaus and specialty
                  agencies (such as agencies that sell information about check
                  writing histories, medical records, and rental history
                  records). Here is a summary of your major{' '}
                </span>
                <span className="c4">
                  <a
                    className="c15"
                    href="https://www.google.com/url?q=https://www.consumer.ftc.gov/sites/default/files/articles/pdf/pdf-0096-fair-credit-reporting-act.pdf&amp;sa=D&amp;source=editors&amp;ust=1731606933088158&amp;usg=AOvVaw16qyf2-AFvtBMtv-V85rHG"
                  >
                    rights under FCRA:
                    www.consumer.ftc.gov/sites/default/files/articles/pdf/pdf-0096-fair-credit-reporting-
                    act.pdf. For more information, including information about
                    additional rights, go to
                  </a>
                </span>
                <span className="c4">&nbsp;</span>
                <span className="c4">
                  <a
                    className="c15"
                    href="https://www.google.com/url?q=https://www.consumerfinance.gov/learnmore&amp;sa=D&amp;source=editors&amp;ust=1731606933088453&amp;usg=AOvVaw2Yehe80Grp2dBm6E-pd9Fc"
                  >
                    www.consumerfinance.gov/learnmore
                  </a>
                </span>
                <span className="c4">
                  &nbsp;or write to: Consumer Financial Protection Bureau, 1700
                  G Street N.W., Washington, DC 20552. You understand and agree
                  that Company is not a credit repair organization as defined
                  under federal or state law, including the Credit Repair
                  Organizations Act.
                </span>
              </li>
              <li className="c62 li-bullet-1">
                <span className="c7">Removal of Information. </span>
                <span className="c4">
                  ONLY THE CREDIT BUREAUS CAN REMOVE OR CORRECT INACCURATE,
                  UNVERIFIABLE, OR OUTDATED INFORMATION, FROM A CREDIT
                  REPORT(S), WHICH HAS NOT BEEN CLAIMED TO BE ACCURATE BY THE
                  CREDITOR WHO SUBMITTED THE INFORMATION TO THE CREDIT BUREAUS.
                  YOU MAY CONTACT THE CREDIT BUREAUS DIRECTLY TO HAVE ANY
                  INACCURATE INFORMATION CORRECTED AND, IF APPROPRIATE, REMOVED
                  BY THE CREDIT BUREAUS TO THE EXTENT REQUIRED UNDER APPLICABLE
                  FEDERAL AND STATE LAWS, INCLUDING THE FAIR CREDIT REPORTING
                  ACT. THEREFORE, IT IS IMPORTANT THAT YOU REALIZE THAT WE
                  CANNOT, NOR CAN ANY CREDIT REPAIR/CARE SERVICE REMOVE
                  INFORMATION FROM YOUR CONSUMER CREDIT PROFILE.
                </span>
              </li>
              <li className="c40 li-bullet-13">
                <span className="c7">Privacy Notice. </span>
                <span className="c4">
                  The information you provide may also be subject to additional
                  terms. For example, the information we collect when you enroll
                  in the Services is subject to additional terms detailing how
                  we use and share your information, which can be found at{' '}
                </span>
                <span className="c4">
                  <a
                    className="c15"
                    href="https://www.google.com/url?q=https://array.pactsafe.io/%23glba&amp;sa=D&amp;source=editors&amp;ust=1731606933088840&amp;usg=AOvVaw30VJZW25NR6lK0EQgYMZ1E"
                  >
                    https://array.pactsafe.io/#glba
                  </a>
                </span>
                <span className="c4">.</span>
              </li>
              <li className="c1 li-bullet-4">
                <span className="c7">FCRA Disclosure. </span>
                <span className="c4">
                  The Fair Credit Reporting Act (FCRA) allows you to obtain from
                  each national credit reporting agency a disclosure of all the
                  information in your credit file at the time of the request.
                  Full disclosure of information in your file at a credit
                  reporting agency must be obtained directly from such credit
                  reporting agency. The credit reports provided through the
                  Services are not intended to constitute the disclosure of
                  information by a credit reporting agency as required by FCRA
                  or similar laws.
                </span>
              </li>
            </ol>
            <ol className="c5 lst-kix_list_1-2 start" start="1">
              <li className="c55 li-bullet-24">
                <span className="c4">
                  Under FCRA you are entitled to receive an annual free
                  disclosure of your credit report from each of the national
                  credit reporting agencies (i.e. Experian, Equifax and
                  TransUnion). To obtain the free reports, you can: (A) visit{' '}
                </span>
                <span className="c4">
                  <a
                    className="c15"
                    href="https://www.google.com/url?q=https://www.annualcreditreport.com/&amp;sa=D&amp;source=editors&amp;ust=1731606933089208&amp;usg=AOvVaw1t0gUZKcpDEhzdXdtxmNfL"
                  >
                    https://www.annualcreditreport.com/
                  </a>
                </span>
                <span className="c4">
                  , (B) call 1-877-322-8228, or (C) complete the{' '}
                </span>
                <span className="c4">
                  <a
                    className="c15"
                    href="https://www.google.com/url?q=https://www.consumer.ftc.gov/sites/www.consumer.ftc.gov/files/articles/pdf/pdf-0093-annual-report-request-form.pdf&amp;sa=D&amp;source=editors&amp;ust=1731606933089404&amp;usg=AOvVaw0nSeT_U5dudFi9nYzRj7m0"
                  >
                    Annual Credit Report Request Form
                  </a>
                </span>
                <span className="c4">
                  &nbsp;and mail it to: Annual Credit Report Request Service,
                  P.O. Box 105281, Atlanta, GA 30348-5281.
                </span>
              </li>
              <li className="c79 li-bullet-13">
                <span className="c4">
                  You are also entitled to receive a free copy of your credit
                  report from a credit reporting agency if: (A) You have been
                  denied or were otherwise notified of an adverse action related
                  to credit, insurance, employment, or a government granted
                  license or other government granted benefit within the past
                  sixty (60) days based on information in a credit report
                  provided by such agency; (B) You have been denied a
                  house/apartment rental or were required to pay a higher
                  deposit than usually required within the past sixty (60) days
                  based on information in a credit report provided by such
                  agency; (C) You certify in writing that you are unemployed and
                  intend to apply for employment during the sixty (60) day
                  period beginning on the date on which you made such
                  certification; (D) You certify in writing that you are a
                  recipient of public welfare assistance; or (E) You certify in
                  writing that you have reason to believe that your file at such
                  credit reporting agency contains inaccurate information due to
                  fraud.
                </span>
              </li>
              <li className="c66 li-bullet-25">
                <span className="c4">
                  FCRA also permits consumers to dispute inaccurate information
                  in their credit report without charge. Accurate information
                  cannot be changed. You do not have to purchase your credit
                  report or other information to dispute inaccurate or
                  incomplete information in your credit file maintained by the
                  credit reporting agencies.
                </span>
              </li>
              <li className="c60 li-bullet-26">
                <span className="c4">
                  In addition, if you reside in the state of Colorado, Maine,
                  Maryland, Massachusetts, New Jersey, or Vermont, you are
                  entitled to receive a free copy of your credit report once a
                  year and if you reside in the state of Georgia, you are
                  entitled to receive a free copy of your credit report twice a
                  year. If you are a resident of Mississippi, then beginning in
                  2016, you are entitled to free credit reports for three (3)
                  years. Otherwise, a consumer reporting agency may impose a
                  reasonable charge for providing you with a copy of your credit
                  report.
                </span>
              </li>
              <li className="c49 li-bullet-2">
                <span className="c4">
                  Any credit report you receive through the Services is not
                  intended to constitute the disclosure of information required
                  by FCRA or similar state laws.
                </span>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </>
  )
}
