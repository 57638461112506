import React from 'react'
import { Header } from 'Components'
import SearchForm from './SearchForm'
import SvgWithText from './SvgWithText'
import wave1 from 'Static/images/wave-1.png'
import laptop from 'Static/images/laptop.png'
import { SvgCheck } from 'Components/Icons'
import CSGHeadshot from 'Static/images/csg-headshot.png'

const styles = {
  infoTypePill:
    'text-xs text-darkerGrey font-bold bg-white rounded-md px-3 py-2 mr-3 mb-3 uppercase tracking-wider',
  sectionHeader: 'text-sectionHeader font-extrabold text-center w-9/12 mb-16',
  svg: 'fill-current text-pink',
  svgBannerItem: 'w-6 h-6 mr-6 flex-shrink-0',
  tos: 'text-center w-9/12 mb-4 text-pink'
}

export default function MainPage(props) {
  const { setBasicInfo, lastError, setLastError } = props

  return (
    <div className="font-proxima-reg">
      {/* Remove Your Personal Info block */}
      <div
        id="info-form"
        className="text-white pt-64px pb-24 bg-heroGradientPersonal bg-cover flex flex-col items-center"
      >
        <Header selected="personal" />

        <h1 className="pageTitle font-proxima-bold mt-16">
          Remove Your Info From the Web
        </h1>

        <div className="flex justify-center w-full bg-gray-200 mt-8">
          <div className="flex flex-col md:flex-row max-w-6xl p-6 overflow-hidden">
            <div className="md:w-3/4 items-center">
              <div className="max-h-[500px] overflow-auto">
                <div className="flex items-center p-4 mb-2 last:mb-0">
                  <SvgCheck
                    className={`${styles.svg} ${styles.svgBannerItem}`}
                    style={{ width: '36px', height: '36px' }}
                  />
                  <p className="m-0 text-black text-left text-xl">
                    Remove your personal information from the web to avoid
                    scams, spam, stalkers, and more
                  </p>
                </div>
                <div className="flex items-center p-4 mb-2 last:mb-0">
                  <SvgCheck
                    className={`${styles.svg} ${styles.svgBannerItem}`}
                    style={{ width: '36px', height: '36px' }}
                  />
                  <p className="m-0 text-black text-left text-xl">
                    200M personal records removed from the web
                  </p>
                </div>
                <div className="flex items-center p-4 mb-2 last:mb-0">
                  <SvgCheck
                    className={`${styles.svg} ${styles.svgBannerItem}`}
                    style={{ width: '36px', height: '36px' }}
                  />
                  <p className="m-0 text-black text-left text-xl">
                    Ongoing monitoring and scanning for new exposures
                  </p>
                </div>
                <div className="flex items-center p-4 mb-2 last:mb-0">
                  <SvgCheck
                    className={`${styles.svg} ${styles.svgBannerItem}`}
                    style={{ width: '36px', height: '36px' }}
                  />
                  <p className="m-0 text-black text-left text-xl">
                    Only <strong>$10.99/mo</strong> (or $132.00/year)
                  </p>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center w-full md:w-1/4 mt-4 md:mt-0">
              <img
                src={CSGHeadshot}
                className="max-w-full"
                style={{ maxHeight: '250px', width: 'auto', height: 'auto' }}
              />
            </div>
          </div>
        </div>

        <p className="my-10 mx-8 text-3xl text-center">
          See if your address, phone number or other{' '}
          <strong>
            info is exposed <br className="hidden md:inline" />
            &nbsp;in seconds
          </strong>{' '}
          &mdash; learn how to remove in a few easy steps.
        </p>

        <SearchForm
          doSearch={setBasicInfo}
          setLastError={setLastError}
          lastError={lastError}
        />

        <img className="laptop mt-16 mb-2" src={laptop} />
      </div>

      {/* 95% of people block */}
      <div className="relative bg-darkerGrey pt-60px pb-60px text-white flex flex-row justify-center items-center">
        <img
          src={wave1}
          className="absolute top-waveGrey left-0 h-waveGrey lg:w-full"
        />
        <div className="w-9/12 flex flex-col md:flex-row justify-center items-center">
          <div className="mr-16">
            <div className="text-9xl font-black leading-none">95%</div>
            <div className="text-sm text-center text-gray-400 leading-none">
              * According to user data
            </div>
          </div>
          <div>
            <div className="text-4xl mb-4 leading-tight">
              of people have exposed personal information online:
            </div>
            <div className="flex flex-row flex-wrap justify-start items-baseline">
              <div className={styles.infoTypePill}>age</div>
              <div className={styles.infoTypePill}>home address</div>
              <div className={styles.infoTypePill}>phone</div>
              <div className={styles.infoTypePill}>relatives</div>
              <div className={styles.infoTypePill}>court records</div>
              <div className={styles.infoTypePill}>email & password</div>
            </div>
          </div>
        </div>
      </div>

      <div className="relative flex flex-col items-center pt-16">
        {/* We make it simple block*/}
        <div className={styles.sectionHeader}>
          How does our technology work?
        </div>

        <div className="w-9/12 text-center max-w-2xl">
          <p className="text-lg -mt-12 mb-16">
            In 3 simple steps, our technology automates over 200 hours of work
            you would have to undertake to remove your information from the web.
          </p>
        </div>

        <div className="w-9/12 flex flex-col lg:flex-row justify-evenly space-y-12 lg:space-y-0 gap-x-8 mb-12 max-w-page">
          <SvgWithText
            type={SvgWithText.Vertical}
            svg="SvgRadar"
            fillStyle={styles.svg}
            title="Search & Find"
            text="We scour the web, including data brokers, people search sites, social media, search engines and more, to find where you might have accessible information like your phone number, email address, family member, government records and more that you may not want or could put you at risk."
          />
          <SvgWithText
            type={SvgWithText.Vertical}
            svg="SvgLockedData"
            fillStyle={styles.svg}
            title="Help Remove & Opt Out"
            text="We reach out to these sites on your behalf and make a series of attempts to remove the information directly or offer steps to complete a removal yourself."
          />
          <SvgWithText
            type={SvgWithText.Vertical}
            svg="SvgMonitoring"
            fillStyle={styles.svg}
            title="Monitor & Track"
            text="We monitor these sites on an ongoing basis to track if the information has indeed been removed or opted out, or to make sure new information hasn’t surfaced. We then re-submit opt outs or guide the user where necessary."
          />
        </div>

        <a href="#info-form" className="button-standard mb-16">
          Scan My Name Now Free
        </a>

        <div className={styles.sectionHeader}>
          Removing your personal information protects you from:
        </div>
        <div className="grid grid-rows-6 lg:grid-rows-3 grid-cols-11 grid-flow-row gap-8 w-9/12 mb-16 max-w-page">
          <SvgWithText
            type={SvgWithText.Horizontal}
            svg="SvgSpam"
            fillStyle={styles.svg}
            title="Identity theft."
            text="Scammers use exposed info to impersonate people, ruin credit and max out cards, which costs time and money to repair."
          />
          <SvgWithText
            type={SvgWithText.Horizontal}
            svg="SvgDarkWeb"
            fillStyle={styles.svg}
            title="Robocalls."
            text="Telemarketers find phone numbers online and use them to disrupt people with unsolicited calls."
            svgStyle="lg:col-start-7"
          />
          <SvgWithText
            type={SvgWithText.Horizontal}
            svg="SvgDarkWeb"
            fillStyle={styles.svg}
            title="Spam, scams and ransomware."
            text="Spammers find email addresses online and use them to disrupt people with spam, scams and viruses."
          />
          <SvgWithText
            type={SvgWithText.Horizontal}
            svg="SvgSpam"
            fillStyle={styles.svg}
            title="Stalkers, creeps and doxxers."
            text="Stalkers use exposed addresses, phone numbers and relatives' names to track down and harass victims."
            svgStyle="lg:col-start-7"
          />
          <SvgWithText
            type={SvgWithText.Horizontal}
            svg="SvgSellPersonalInfo"
            fillStyle={styles.svg}
            title="Continual re-exposure."
            text="Data Brokers continually re-publish pieces of personally identifiable info, which shows up in Google."
          />
          <SvgWithText
            type={SvgWithText.Horizontal}
            svg="SvgSellPersonalInfo"
            fillStyle={styles.svg}
            title="Embarassing disclosures."
            text="Anyone who Googles you can find your divorce records, traffic violations, age, net worth, and more."
            svgStyle="lg:col-start-7"
          />
        </div>
        <a href="/terms-of-service" className={styles.tos}>
          Terms of Service
        </a>
      </div>
    </div>
  )
}
